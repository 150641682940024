
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Terms extends Vue {

    created(): void {
        this.$meta.use({
            title: 'Terms of Use | StatHub.gg',
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: 'StatHub.gg - Terms of Use.'
            }
        })
    }

}
